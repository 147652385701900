import React from 'react';

import { SmartTable } from '../SmartTable';

import {
  Card, CardHeader
} from '@material-ui/core';
import { i18n } from "../../config";
import VirtualBatteryDownload from "../BateriaVirtual/VirtualBatteryDownload";
import { LoadingAnimation } from '../LoadingAnimation';
import Settings from '../../settings';
import BateriaVirtualDialogOpenButton from '../BateriaVirtual/BateriaVirtualDialogOpenButton';

import BateriaVirtualDiscountSelect from './BateriaVirtualDiscountSelect';
import BateriaVirtualDiscountToggle from './BateriaVirtualDiscountToggle';
/**
 * Feature component
 * 
 * Responsible for rendering a card with the virtual batteries to show the data properly
 * 
 */
const BateriaVirtualCardList = ({element, handleBatteryModeChange, virtualBatteryTypes, contracts}) => {

  const styles = {
    wrapper: {
      flexWrap: 'wrap',
      display: 'flex',
    },
    entry: {
      marginBottom: '20px',
    },
  };

  const setTotalDescompte = (bateria) => {
    if (bateria.total_descompte === null) {
      bateria.total_descompte = 0.0;
    }
  };

    
    let tableHead = [
      {
        title: i18n.t('common:text.altHome_battery'),
      }, 
      {
        title: i18n.t('common:text.altHome_accumulated'),
      }, 
      {
        title: i18n.t('common:text.virtual_battery_percentage'),
      },
      {
        title: i18n.t('common:text.altHome_to_discount'),
      },
    ];
    let contentRows = [];

    element.bateria_virtual.forEach((bateria, index) => {
      setTotalDescompte(bateria);

      let ncontracts = 0;
      let hasEditPercentageActionHeader = false;
      for (let j = 0; j < contracts.length; j++) {
        for (let k = 0; k < contracts[j].bateria_virtual.length; k++) {
          if (bateria.bateria.id === contracts[j].bateria_virtual[k].bateria.id) {
            ncontracts++;
          }
        }
      }

      let bateriaContent = {
        "name": bateria.bateria.name,
        "accumulated": bateria.total_descompte,
        "percentage": bateria.pes_percentatge
      };

      bateriaContent = [bateria.bateria.name, bateria.total_descompte, bateria.pes_percentatge]

      let discountComponent = undefined;
      if (Settings?.contract?.batteryRenderType?.select === "toggle") {
        if (virtualBatteryTypes.types && virtualBatteryTypes.types.length) {
          discountComponent = 
          <BateriaVirtualDiscountToggle   
            bateria={bateria}
            handleBatteryModeChange={handleBatteryModeChange}
            virtualBatteryTypes={virtualBatteryTypes}
          ></BateriaVirtualDiscountToggle>
        }
      } else {
        discountComponent =
          <BateriaVirtualDiscountSelect
            bateria={bateria}
            handleBatteryModeChange={handleBatteryModeChange}
            virtualBatteryTypes={virtualBatteryTypes}
          ></BateriaVirtualDiscountSelect>
      }
      bateriaContent.push(discountComponent);

      if (ncontracts > 1) {
        if (!hasEditPercentageActionHeader) {
          tableHead.push({ title: 'a', key: "editPercentage" });
          hasEditPercentageActionHeader = true;
        }
        bateriaContent.push(<BateriaVirtualDialogOpenButton bateria_virtual={bateria} />);
      }

      contentRows.push(bateriaContent);
    });

    return (
      <>
      <Card style={styles.entry}>
        <div style={styles.wrapper}>
          <CardHeader title={ i18n.t("common:text.altHome_virtual_battery") } style={{flexGrow: 1}}/>
          <VirtualBatteryDownload bateries={element.bateria_virtual}></VirtualBatteryDownload>
        </div>
        <SmartTable
          header={tableHead}
          data={contentRows}
      />
      </Card>

      </>
    );
};

export default BateriaVirtualCardList;