import Settings from "../settings";
import {
  data_fetch_api_resource,
  data_create_api_resource,
} from "../utils/http_functions";
import { parseJSON } from "../utils/misc";
import { logoutAndRedirect } from "./auth";
import {
  SUBMIT_ABOUT_HOME_DATA,
  STORE_SIPS_DATA,
  STORE_SIPS_GAS_DATA,
  STORE_CUPS_DATA,
  STORE_RESIDENCE_TYPE_DATA,
  STORE_UPLOADED_INVOICES_DATA,
  SUBMIT_ABOUT_YOU_DATA,
  SET_POWER,
  SET_POWER2,
  SET_POWER3,
  SET_POWER4,
  SET_POWER5,
  SET_POWER6,
  SET_ACTIVE_CONTRACT,
  SET_SELFCONS,
  SET_SELFCONS_ATTACHMENT,
  SET_ACCESS_TARIFF,
  SET_CUPS_VALID,
  SET_CUPS_INVALID,
  SUBMIT_ABOUT_CONTRACT_DATA,
  SUBMIT_ABOUT_GAS_CONTRACT_DATA,
  SUBMIT_PAYMENT_DATA,
  SUBMIT_CONFIRM_DATA,
  SELECT_PRODUCT,
  FETCH_AVAILABLE_PRODUCTS_REQUEST,
  RECEIVE_AVAILABLE_PRODUCTS,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE,
  FETCH_CONTRACTATION_CONFIG_REQUEST,
  RECEIVE_CONTRACTATION_CONFIG,
} from "../constants";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import { i18n } from "../config";
import _ from "lodash";

// ----------------------------------------------------------------------------
// New Electricity Contract Form
// ----------------------------------------------------------------------------

export const submitAboutHomeData = ({
  leadsConsent,
  cups,
  address,
  residenceType,
  cnae,
  invoice,
  comerOrigin,
  activeContract
}) => {
  return {
    type: SUBMIT_ABOUT_HOME_DATA,
    payload: {
      leadsConsent,
      cups,
      address,
      residenceType,
      cnae,
      invoice,
      comerOrigin,
      activeContract
    },
  };
};

export const submitAboutYouData = ({
  vat,
  company,
  name,
  surName1,
  surName2,
  nifEmpresa,
  changeOwner,
  vatRepresentante,
  nameRepresentante,
  surName1Representante,
  surName2Representante,
  sameInvoiceAddress,
  invoiceAddress,
  commsLanguage,
  email,
  mobile,
  nifFaceup,
}) => {
  return {
    type: SUBMIT_ABOUT_YOU_DATA,
    payload: {
      vat,
      company,
      name,
      surName1,
      surName2,
      nifEmpresa,
      changeOwner,
      vatRepresentante,
      nameRepresentante,
      surName1Representante,
      surName2Representante,
      sameInvoiceAddress,
      invoiceAddress,
      commsLanguage,
      email,
      mobile,
      nifFaceup,
    },
  };
};

export const setPower = (value) => {
  return {
    type: SET_POWER,
    payload: {
      power: value,
    }
  };
};

export const setPower2 = (value) => {
  return {
    type: SET_POWER2,
    payload: {
      power: value,
    }
  };
};

export const setPower3 = (value) => {
  return {
    type: SET_POWER3,
    payload: {
      power: value,
    }
  };
};

export const setPower4 = (value) => {
  return {
    type: SET_POWER4,
    payload: {
      power: value,
    }
  };
};

export const setPower5 = (value) => {
  return {
    type: SET_POWER5,
    payload: {
      power: value,
    }
  };
};

export const setPower6 = (value) => {
  return {
    type: SET_POWER6,
    payload: {
      power: value,
    }
  };
};

export const setActiveContract = (value) => {
  return {
    type: SET_ACTIVE_CONTRACT,
    payload: {
      activeContract: value,
    }
  };
};

export const setSelfcons = (value) => {
  return {
    type: SET_SELFCONS,
    payload: {
      selfcons: value,
    }
  };
};

export const updateResidenceType = (value) => {
  return {
    type: STORE_RESIDENCE_TYPE_DATA,
    payload: {
      residenceType: value
    }
  }
};

export const updateUploadedFiles = (value) => {
  return {
    type: STORE_UPLOADED_INVOICES_DATA,
    payload: {
      invoice: value
    }
  }
};

export const setSelfconsAttachment = (value) => {
  return {
    type: SET_SELFCONS_ATTACHMENT,
    payload: {
      selfconsAttachment: value,
    }
  };
};

export const setAccessTariff = (value, name) => {
  return {
    type: SET_ACCESS_TARIFF,
    payload: {
      accessTariff: value,
      accessTariffName: name
    }
  };
};

export const submitAboutContractData = ({
  power,
  power2,
  power3,
  power4,
  power5,
  power6,
  accessTariff,
  selectedProduct,
  selfcons,
  selfconsAttachment,
}) => {
  return {
    type: SUBMIT_ABOUT_CONTRACT_DATA,
    payload: {
      power,
      power2,
      power3,
      power4,
      power5,
      power6,
      accessTariff,
      selectedProduct,
      selfcons,
      selfconsAttachment,
    },
  };
};

export const submitAboutGasContractData = ({
  cupsGas,
  comerGasOrigin,
  selectedGasProduct,
}) => {
  return {
    type: SUBMIT_ABOUT_GAS_CONTRACT_DATA,
    payload: {
      cupsGas,
      comerGasOrigin,
      selectedGasProduct,
    },
  };
};

export const submitPaymentData = ({ iban, isOwner }) => {
  return {
    type: SUBMIT_PAYMENT_DATA,
    payload: {
      iban,
      isOwner,
    },
  };
};

export const submitConfirmData = ({
  promotionalCode,
  acceptOVConditions,
  acceptGdpr,
}) => {
  return {
    type: SUBMIT_CONFIRM_DATA,
    payload: {
      promotionalCode,
      acceptOVConditions,
      acceptGdpr,
    },
  };
};

// ----------------------------------------------------------------------------
// Contractation config (prefilled fields)
// ----------------------------------------------------------------------------

export const fetchContractationConfigRequest = () => {
  return {
    type: FETCH_CONTRACTATION_CONFIG_REQUEST,
  };
};

export const receiveContractationConfig = (response) => {
  return {
    type: RECEIVE_CONTRACTATION_CONFIG,
    payload: {
      contractationConfig: response,
    },
  };
};

export const fetchContractationConfig = () => {
  return async (dispatch) => {
    dispatch(fetchContractationConfigRequest());
    try {
      const json = await data_fetch_api_resource(null, `contractacio/config`);
      const response = await parseJSON(json);
      dispatch(receiveContractationConfig(response));
    } catch (e) {
      Sentry.captureException(e);
      if (
        Settings.newElectricityContractation &&
        Settings.newContract.authenticate &&
        e.status === 401
      ) {
        dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveContractationConfig(null));
      }
    }
  };
};

// ----------------------------------------------------------------------------
// CUPS Information
// ----------------------------------------------------------------------------

export const storeCUPSData = (data) => {
  return {
    type: STORE_CUPS_DATA,
    payload: {
      cups: data
    }
  }
}

export const setCupsAvailability = (type, data) => {
  return {
    type: type,
    payload: {
      ...data
    },
  };
}

export const validateCUPSAvailability = (values, keepSIPSData = false) => {
  return async (dispatch) => {
    await data_fetch_api_resource(
      null,
      `contractacio/config/cups/${values.cups}`
    ).then((result) => {
      let response = null;
      if (result && result.status === 200) {
        if (!_.get(result, 'data.cups_availability.available', false)) {
          dispatch(
            setCupsAvailability(SET_CUPS_INVALID, {
              invalid: true,
              reason: _.get(result, 'data.cups_availability.reason', 'Invalid CUPS')
            })
          );
        } else {
          dispatch(setCupsAvailability(SET_CUPS_VALID, {invalid: null, reason: ''}));
          if(keepSIPSData){
            dispatch(storeSIPSData({
              ...result.data,
            }));
          }
        }
      } else {
        response = i18n.t('common:text.cannot_validate_async');
      }

      return (response && { cups: response }) || null;
    })
  }
};

export const storeSIPSData = (data) => {
  return {
    type: STORE_SIPS_DATA,
    payload: {
      sipsData: data,
    },
  };
};

// ----------------------------------------------------------------------------
// Products
// ----------------------------------------------------------------------------

export const selectProduct = (product) => {
  return {
    type: SELECT_PRODUCT,
    payload: {
      product,
    },
  };
};

export const fetchAvailableProductsRequest = () => {
  return {
    type: FETCH_AVAILABLE_PRODUCTS_REQUEST,
  };
};

const getFixedProductNameByParam = () => {
  const urlParams = window.location.search;
  let fixedProductName;
  if (urlParams) {
    // Get URL parameters object
    const values = queryString.parse(urlParams);
    fixedProductName = values.tarifa;
  }
  return fixedProductName;
};

const filterAvailableProductsByUrlParam = (currentAvailableProducts) => {
  // If we receive a product name by URL parameter we will only show that
  // product.
  // If we don't have any product with that name, we won't show any
  // selectable product.
  const fixedProductName = getFixedProductNameByParam();
  if (!fixedProductName) {
    return currentAvailableProducts;
  }
  let filteredAvailableProducts;
  let productMatch = currentAvailableProducts.find(
    (t) => t.name === fixedProductName
  );
  if (productMatch) {
    // We only show one selectable product.
    filteredAvailableProducts = [productMatch];
  } else {
    // We don't show any product
    filteredAvailableProducts = [];
  }
  return filteredAvailableProducts;
};

export const receiveAvailableProducts = (response) => {
  const filteredProducts = filterAvailableProductsByUrlParam(response);
  return {
    type: RECEIVE_AVAILABLE_PRODUCTS,
    payload: {
      availableProducts: filteredProducts,
    },
  };
};

export const fetchAvailableProducts = (
  power,
  accessTariff,
  selfConsumption,
  cups
) => {
  return async (dispatch) => {
    dispatch(fetchAvailableProductsRequest());
    try {
      const json = await data_fetch_api_resource(
        null,
        `pricelist?power=${power}&atr=${accessTariff.name || accessTariff}&selfcons=${selfConsumption || false}&cups=${cups}`
      );
      const response = await parseJSON(json);
      dispatch(receiveAvailableProducts(response));
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      if (
        Settings.newElectricityContractation &&
        Settings.newContract.authenticate &&
        e.status === 401
      ) {
        dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveAvailableProducts([]));
      }
    }
  };
};

export const confirmNewContractRequest = () => {
  return {
    type: NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST,
  };
};

export const receiveConfirmNewContractResponse = (response) => {
  return {
    type: NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE,
    payload: {
      confirmResponse: response,
    },
  };
};

export const confirmNewContract = (postData) => {
  return async (dispatch) => {
    dispatch(confirmNewContractRequest());
    try {
      const json = await data_create_api_resource(
        null,
        "leads",
        postData
      );
      const response = await parseJSON(json);
      dispatch(receiveConfirmNewContractResponse(response));
    } catch (e) {
      Sentry.captureException(e);
      if (
        Settings.newElectricityContractation &&
        Settings.newContract.authenticate &&
        e.status === 401
      ) {
        dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveConfirmNewContractResponse({
          result: {
            error: true,
            render: {
              format: 'markdown',
              body: i18n.t('common:text.contractation_connectivity_error')
            }
          }
        }));
      }
    }
  };
};
