/* eslint new-cap: 0 */

import React from "react";
import { Redirect, Route, Switch, generatePath } from "react-router-dom";

/* containers */
import { App } from "./containers/App";
import LoginView from "./components/LoginView";
import ConnectView from "./components/ConnectView";
import LogoutView from "./components/LogoutView";
import ChangePasswordView from "./components/ChangePasswordView";
import LoadingView from "./components/LoadingView";
import ReclamATC from "./components/ReclamATC";
import CRMForm from "./components/CRMForm";
import UnsignedCRM from "./components/UnsignedCRM";
import NotFound from "./components/NotFound";
import MaintenanceView from "./components/MaintenanceView";

import SignUpView from "./components/SignUpView";
import ContractsNew from "./components/ContractsTabs";
import ContractGas from "./components/ContractGas";
import AltContract from "./components/AltContractView";
import Invoices from "./components/InvoicesViewTabs";
import AltHome from "./components/AltHome/AltHomeView";
import NewElectricityContract from "./components/NewElectricityContract";
import NewGasContract from "./components/NewGasContract";
import UserResetPassword from "./components/UserResetPassword";
import CalculadoraPotencia from "./components/CalculadoraPotencia";
import PriceComparator from "./components/PriceComparator";
import GasPriceComparator from "./components/GasPriceComparator";

import Home from "./overrides/pages/Home";

import CookieDialog from './components/CookieDialog';

import { DetermineAuth } from "./components/DetermineAuth";
import { requireAuthentication } from "./components/AuthenticatedComponent";
import { requireNoAuthentication } from "./components/notAuthenticatedComponent";
import Settings from "./settings";
import Cookies from "./utils/cookies";
import _ from "lodash";

import { withTranslation } from "react-i18next";
import { i18n, dayjs } from "./config";
import { contraction_url, locale_code } from "./constants/i18n";
import { resources as languages } from "./i18n";

// Generate routes with other languages and redirect to the configured lang
const altLangRoutes = () => {
  let altRoutes = [];
  for (const [key, value] of Object.entries(languages)) {
    if (key !== locale_code) {
      Object.entries(value.common.url).map((item) =>
        altRoutes.push(
          <Route
            exact
            path={item[1]}
            key={item[0]}
            component={(e) => (
              <Redirect
                to={generatePath(i18n.t("common:url." + item[0]), e.match.params) + e.location.search}
              />
            )}
          />
        )
      );
    }
  }
  return altRoutes;
};

const maintenanceView = () => {
  let activated =
    _.get(Settings, "maintenance.activated") && !Cookies.getMaintenanceBypass();
  let startDate = _.get(Settings, "maintenance.starts");
  if (startDate) {
    activated = activated && dayjs().isSameOrAfter(startDate);
  }

  return activated;
};
const homepage = _.get(Settings, "homepage", "invoices");

const Routes = ({ t, i18n }) => {
  return (
    <React.Fragment>
      {maintenanceView() ? (
        <Switch>
          <Route exact path={"/maintenance"} component={MaintenanceView} />
          <Route path="*" render={() => <Redirect to={"/maintenance"} />} />
        </Switch>
      ) : (
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => {
              if (props.location.search.includes("promocode")) {
                  window.location = `${contraction_url}${props.location.search}`;
                  return;
              }
              return <Redirect to={{ pathname: t("common:url." + homepage), search: props.location.search}} />}
            }
          />
          <Route
            exact
            path={t("common:url.contracts") + "/:id"}
            render={() => <Redirect to={t("common:url.contracts")} />}
          />
          <Route exact path={t("common:url.welcome")} component={Home} />
          <App>
            <Switch>
              <Route
                exact
                path={t("common:url.login")}
                component={requireNoAuthentication(LoginView)}
              />
              <Route
                exact
                path={t("common:url.connect")}
                component={requireNoAuthentication(ConnectView)}
              />
              <Route
                exact
                path={t("common:url.newElectricityContract")}
                component={
                  _.get(Settings, "features.newElectricityContractation", false)
                    ? _.get(Settings, "newContract.authenticate", false)
                      ? requireAuthentication(NewElectricityContract)
                      : DetermineAuth(NewElectricityContract)
                    : DetermineAuth(NotFound)
                }
              />
              <Route
                exact
                path={t("common:url.newDualContract")}
                component={
                  _.get(Settings, "features.newContractType", "none") === "dual"
                    ? _.get(Settings, "newDualContract.authenticate", false)
                      ? requireAuthentication(() => (
                          <NewElectricityContract gas={true} />
                        ))
                      : DetermineAuth(() => (
                          <NewElectricityContract gas={true} />
                        ))
                    : DetermineAuth(NotFound)
                }
              />
              <Route
                exact
                path={t("common:url.newGasContract")}
                component={
                  _.get(Settings, "features.newGasContractation", false)
                    ? _.get(Settings, "newGasContract.authenticate")
                      ? requireAuthentication(NewGasContract)
                      : DetermineAuth(NewGasContract)
                    : DetermineAuth(NotFound)
                }
              />
              <Route
                exact
                path={t("common:url.contact")}
                component={requireAuthentication(CRMForm)}
              />
              <Route
                exact
                path={t("common:url.unsigned_contact")}
                component={requireNoAuthentication(UnsignedCRM)}
              />
              <Route
                exact
                path={t("common:url.reclamations")}
                component={requireAuthentication(ReclamATC)}
              />
              <Route
                exact
                path={t("common:url.activate")}
                component={requireNoAuthentication(SignUpView)}
              />
              <Route
                exact
                path={t("common:url.changePassword")}
                component={requireAuthentication(ChangePasswordView)}
              />
              <Route
                exact
                path={t("common:url.alt_contracts_detail")}
                component={requireAuthentication(AltContract)}
              />
              <Route
                exact
                path={t("common:url.loading")}
                component={DetermineAuth(LoadingView)}
              />
              <Route
                exact
                path={t("common:url.contracts")}
                component={requireAuthentication(ContractsNew)}
              />
              <Route
                exact
                path={t("common:url.logout")}
                component={DetermineAuth(LogoutView)}
              />
              <Route
                exact
                path={t("common:url.resetPassword")}
                component={requireNoAuthentication(() => (
                  <UserResetPassword />
                ))}
              />
              {_.get(Settings, "powerCalculator.enabled", false) &&
              <Route
                exact
                path={t("common:url.powerCalculator")}
                component={DetermineAuth((props) => (
                  <CalculadoraPotencia {...props} />
                ))}
              />
              }
              {_.get(Settings, "comparator.enabled", false) &&
              <Route
                exact
                path={t("common:url.priceComparator")}
                component={DetermineAuth(PriceComparator)}
              />
              }
              {_.get(Settings, "altHome.enabled", false) ?
                <Route
                  exact
                  path={t("common:url.altHome")}
                  component={DetermineAuth(AltHome)}
                />
                :
                <Route
                  exact
                  path={t("common:url.altHome")}
                  render={() => <Redirect to={t("common:url." + homepage)} />}
                />
              }
              <Route
                exact
                path={t("common:url.contracts_gas")}
                component={DetermineAuth(ContractGas)}
              />
              <Route
                exact
                path={t("common:url.invoices")}
                component={requireAuthentication((props) => (
                  <Invoices showGas={true} {...props} />
                ))}
              />
              <Route
                exact
                path={t("common:url.contracts_invoices")}
                component={requireAuthentication((props) => (
                  <Invoices showGas={true} {...props} />
                ))}
              />
              <Route
                exact
                path={t("common:url.gasPriceComparator")}
                component={DetermineAuth(GasPriceComparator)}
              />
              {altLangRoutes()}
              <Route path="*" component={DetermineAuth(NotFound)} />
            </Switch>
          </App>
        </Switch>
      )}
    </React.Fragment>
  );
};

export default withTranslation(["common"])(Routes);
