import React from 'react';

import {
  MenuItem,
  Select
} from '@material-ui/core';


/**
 * UI component
 * 
 * Responsible for rendering the toggle choose if the discount have to be applied or not for batteria virtual
 */
const BateriaVirtualDiscountSelect = ({
  bateria,
  handleBatteryModeChange,
  virtualBatteryTypes
}) => {
  return (
    <Select
    disabled={virtualBatteryTypes?.loading ?? true}
    value={bateria.gestio_descomptes}
    onChange={(event) => {
      handleBatteryModeChange(event.target.value, bateria.bateria.id)}}
  >
    {virtualBatteryTypes && virtualBatteryTypes.types?.length > 0 &&
      virtualBatteryTypes.types.map((p, idx) => {
        return  <MenuItem key={idx} value={p.name}>{p.description}</MenuItem>
      })
    }
  </Select>
  );
}

export default BateriaVirtualDiscountSelect;