import React, { useState } from 'react';
import { ComposedChart, Area, Line, Tooltip, Brush, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend  } from 'recharts';
import { default as CustomTooltip } from "./Tooltip";
import { theme } from '../../config';
import { useChart } from './useChart';

const styles = {
  legend: {
    width: "100%",
    paddingTop: "1vh",
  },
};

export const AreaChart = ({ 
  data, 
  components, 
  stacked,
  unit = 'kWh', 
  groupTitle = "", 
  ignoreMaxContracts = false,
}) => {
  const [hover, setHover] = useState(null);

  const handleLegendMouseEnter = (e) => {
    setHover(e.dataKey);
  };

  const handleLegendMouseLeave = (e) => {
    setHover(null);
  };

  const {colors, dataAdapted, fields, toggleBarVisibility} = useChart({
      "data": data ?? [], 
      components, 
      "compoundBars": false, 
      ignoreMaxContracts
    }
  );

  const renderAreas = () => {
    return fields && Object.keys(fields)
      .sort() //sort by key ASC
      .map((component, i) => <Area
        unit={unit}
        isAnimationActive={true}
        key={"line" + i}
        type='monotone'
        dataKey={component}
        stackId={stacked}
        stroke={colors[i]}
        strokeWidth={3}
        fillOpacity={1}
        fill={`url(#color${i})`}
        hide = {fields[component] === false}
      />
    );
  }

  const createGradients = () => {
    return colors.map((color, i) => {
      return <defs key={"color" + i}>
        <linearGradient id={"color" + i} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.5}/>
            <stop offset="90%" stopColor={color} stopOpacity={0.1}/>
            <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0.1}/>
        </linearGradient>
      </defs>;
    })
  }

  return (
    <ResponsiveContainer height={500}>
      <ComposedChart
        data={dataAdapted}
        margin={{ top: 20, left: 20 }}
        isAnimationActive={true}
      >
        {createGradients()}

        <XAxis dataKey="name" angle={-45} height={55} dy={15} label={{ value: groupTitle, fill: '#666666', paddingTop: 20 }} />
        <YAxis label={{ value: unit, angle: unit.length > 1 ? -90 : 0, position: 'insideLeft', fill: '#666666' }} />
        <CartesianGrid strokeDasharray="3 3"/>

        {renderAreas()}

        <Legend
          width={100}
          layout="horizontal"
          align="center"
          formatter={(value, entry, index) => {
            return <span style={{ 
              color: entry.inactive ? "gray" : "black", 
              textShadow: entry.value === hover ? `0 2px 10px ${colors[index]}` : "", 
              fontWeight: "400",
              cursor: "pointer" }}
            >
              {value}
            </span>
          }}
          wrapperStyle={styles.legend}
          onClick={e => toggleBarVisibility(e.dataKey)}
          onMouseOver={handleLegendMouseEnter}
          onMouseOut={handleLegendMouseLeave}
        />

        <Tooltip content={<CustomTooltip groupTitle={groupTitle} />} />
        <Brush dataKey="name" height={30} stroke={theme.palette.primary.main} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}