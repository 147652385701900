import _ from "lodash";
import Settings from "../settings";
import {
  SUBMIT_ABOUT_HOME_DATA,
  SUBMIT_ABOUT_YOU_DATA,
  SUBMIT_PAYMENT_DATA,
  SET_ACCESS_TARIFF,
  SET_POWER,
  SET_POWER2,
  SET_POWER3,
  SET_POWER4,
  SET_POWER5,
  SET_POWER6,
  SET_ACTIVE_CONTRACT,
  SET_CUPS_VALID,
  SET_CUPS_INVALID,
  SET_SELFCONS,
  SET_SELFCONS_ATTACHMENT,
  SUBMIT_ABOUT_CONTRACT_DATA,
  SUBMIT_ABOUT_GAS_CONTRACT_DATA,
  SUBMIT_CONFIRM_DATA,
  STORE_CUPS_DATA,
  STORE_RESIDENCE_TYPE_DATA,
  STORE_UPLOADED_INVOICES_DATA,
  STORE_SIPS_DATA,
  FETCH_AVAILABLE_PRODUCTS_REQUEST,
  RECEIVE_AVAILABLE_PRODUCTS,
  SELECT_PRODUCT,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE,
  FETCH_CONTRACTATION_CONFIG_REQUEST,
  RECEIVE_CONTRACTATION_CONFIG,
} from "../constants";
import { createReducer } from "../utils/misc";


const initialState = {
  leadsConsent: false,

  cups: null,
  cupsAvailability: {
    invalid: null,
    reason: ''
  },

  address: {},
  residenceType: _.get(Settings, "newContract.defaultCnaeCheck", true) ? "habitual" : null,
  cnae: null,
  invoice: null,
  comerOrigin: null,
  activeContract: null,

  vat: null,
  company: false,
  name: null,
  surName1: null,
  surName2: null,
  cif: null,
  changeOwner: null,
  vatRepresentante: null,
  nameRepresentante: null,
  surName1Representante: null,
  surName2Representante: null,
  sameInvoiceAddress: true,
  invoiceAddress: {},
  commsLanguage: null,
  email: null,
  mobile: null,

  power: null,
  power2: null,
  power3: null,
  power4: null,
  power5: null,
  power6: null,
  accessTariff: null,
  selfcons: null,
  selfconsAttachment: null,
  selfconsCode: null,

  includeGas: _.get(Settings, 'newDualContract.gasIsMandatory', false),

  availableProducts: [],
  selectedProduct: null,

  iban: null,
  isOwner: false,

  promotionalCode: null,
  acceptOVConditions: false,
  acceptGdpr: false,

  isConfirming: false,
};

let reducerMap = {
  [SUBMIT_ABOUT_HOME_DATA]: (state, payload) =>
    Object.assign({}, state, {
      leadsConsent: payload.leadsConsent,
      cups: payload.cups,
      address: payload.address,
      residenceType: payload.residenceType,
      cnae: payload.cnae,
      invoice: payload.invoice,
      comerOrigin: payload.comerOrigin,
      activeContract: payload.activeContract
    }),
  [SUBMIT_ABOUT_YOU_DATA]: (state, payload) => {
    let newState = {
      ...state,
      vat: payload.vat,
      company: payload.company,
      name: payload.name,
      surName1: payload.surName1,
      surName2: payload.surName2,
      changeOwner: payload.changeOwner,
      vatRepresentante: payload.vatRepresentante,
      nameRepresentante: payload.nameRepresentante,
      surName1Representante: payload.surName1Representante,
      surName2Representante: payload.surName2Representante,
      sameInvoiceAddress: payload.sameInvoiceAddress,
      invoiceAddress: payload.invoiceAddress,
      commsLanguage: payload.commsLanguage,
      email: payload.email,
      mobile: payload.mobile,
      nifFaceup: payload.nifFaceup,
      nifEmpresa: payload.nifEmpresa
    };
    return newState;
  },
  [SET_ACCESS_TARIFF]: (state, payload) =>
    Object.assign({}, state, {
      accessTariff: payload.accessTariff,
      accessTariffName: payload.accessTariffName
    }),
  [SET_POWER]: (state, payload) =>
    Object.assign({}, state, {
      power: payload.power,
    }),
  [SET_POWER2]: (state, payload) =>
  Object.assign({}, state, {
    power2: payload.power,
  }),
  [SET_POWER3]: (state, payload) =>
  Object.assign({}, state, {
    power3: payload.power,
  }),
  [SET_POWER4]: (state, payload) =>
  Object.assign({}, state, {
    power4: payload.power,
  }),
  [SET_POWER5]: (state, payload) =>
  Object.assign({}, state, {
    power5: payload.power,
  }),
  [SET_POWER6]: (state, payload) =>
  Object.assign({}, state, {
    power6: payload.power,
  }),
  [SET_ACTIVE_CONTRACT]: (state, payload) =>
  Object.assign({}, state, {
    activeContract: payload.activeContract,
  }),
  [SET_SELFCONS]: (state, payload) =>  {
    let newState = {
      ...state,
      selfcons: payload.selfcons === 'true' || payload.selfcons === true, // El resultat d'un radio button sempre és un string
    };
    if (newState.selfcons && !state.sipsData) {
      newState['selfconsCode'] = '41'
    }
    return newState;
  },
  [SET_SELFCONS_ATTACHMENT]: (state, payload) =>
  Object.assign({}, state, {
    selfconsAttachment: payload.selfconsAttachment,
  }),
  [SUBMIT_ABOUT_CONTRACT_DATA]: (state, payload) =>
    Object.assign({}, state, {
      power: payload.power,
      power2: payload.power2,
      power3: payload.power3,
      power4: payload.power4,
      power5: payload.power5,
      power6: payload.power6,
      accessTariff: payload.accessTariff,
      selectedProduct: payload.selectedProduct,
      selfcons: payload.selfcons,
      selfconsAttachment: payload.selfconsAttachment,
    }),
  [SUBMIT_ABOUT_GAS_CONTRACT_DATA]: (state, payload) =>
    Object.assign({}, state, {
      cupsGas: payload.cupsGas,
      comerGasOrigin: payload.comerGasOrigin,
      selectedGasProduct: payload.selectedGasProduct,
    }),
  [SUBMIT_PAYMENT_DATA]: (state, payload) =>
    Object.assign({}, state, {
      iban: payload.iban,
      isOwner: payload.isOwner,
    }),
  [SUBMIT_CONFIRM_DATA]: (state, payload) =>
    Object.assign({}, state, {
      promotionalCode: payload.promotionalCode,
      acceptOVConditions: payload.acceptOVConditions,
      acceptGdpr: payload.acceptGdpr,
    }),
  [SELECT_PRODUCT]: (state, payload) =>
    Object.assign({}, state, {
      selectedProduct: payload.product,
    }),
  [FETCH_AVAILABLE_PRODUCTS_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isFetching: true,
    }),
  [RECEIVE_AVAILABLE_PRODUCTS]: (state, payload) =>
    Object.assign({}, state, {
      availableProducts: payload.availableProducts,
      isFetching: false,
    }),
  [NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isConfirming: true,
    }),
  [NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE]: (state, payload) =>
    Object.assign({}, state, {
      confirmResponse: payload.confirmResponse,
      isConfirming: false,
    }),
  [FETCH_CONTRACTATION_CONFIG_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isFetching: true,
    }),
  [RECEIVE_CONTRACTATION_CONFIG]: (state, payload) =>
    Object.assign({}, state, {
      contractationConfig: payload.contractationConfig,
      ...payload.contractationConfig,
      isFetching: false,
    }),
  [STORE_SIPS_DATA]: (state, payload) => {
    let sipsData = true;
    let toStoreSipsData = {
      selfcons: !_.isNull(_.get(payload, 'sipsData.selfcons')) ? payload.sipsData.selfcons : null,
      power: !_.isNull(_.get(payload, 'sipsData.power_p1')) ? payload.sipsData.power_p1 : false,
      power2: !_.isNull(_.get(payload, 'sipsData.power_p2')) ? payload.sipsData.power_p2 : false,
      cnmc: !_.isNull(_.get(payload, 'sipsData.access_tariff.cnmc')) ? payload.sipsData.access_tariff.cnmc : false,
      accessTariff: !_.isNull(_.get(payload, 'sipsData.access_tariff.id')) ? payload.sipsData.access_tariff.id : '',
      accessTariffName: !_.isNull(_.get(payload, 'sipsData.access_tariff.name')) ? payload.sipsData.access_tariff.name : false,
      address: {
        zipcode: !_.isNull(_.get(payload, 'sipsData.address.zipcode')) ? payload.sipsData.address.zipcode : false
      },
      selfconsCode: !_.isNull(_.get(payload, 'sipsData.autoconsumo_code')) ? String(payload.sipsData.autoconsumo_code) : '00',
    };
    Object.values(toStoreSipsData).forEach( value => {
      if(value && value.constructor === Object) { // To check dictionaries
        Object.values(value).forEach( innerValue => {
          sipsData = sipsData && !!innerValue;
        });
      } else {
        sipsData = sipsData && !!value;
      }
    });
    let newState = {
      ...state,
      ...toStoreSipsData,
      sipsData,
    }
    return newState;
  },
};

// I do this to avoid code duplication in the reducerMap.
[SET_CUPS_INVALID, SET_CUPS_VALID].map(
  type => reducerMap[type] = (state, payload) => {
    return {
      ...state,
      cupsAvailability: {
        ...payload
      }
    }
  }
);

[
  STORE_CUPS_DATA,
  STORE_RESIDENCE_TYPE_DATA,
  STORE_UPLOADED_INVOICES_DATA
].map(
  type => reducerMap[type] = (state, payload) => {
    let newState = {
      ...state,
      ...payload
    };
    return newState;
  }
);

export default createReducer(initialState, reducerMap);