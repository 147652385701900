import { Grid, Switch, Typography } from "@material-ui/core";
import React from 'react';

/**
 * UI component
 * 
 * Responsible for rendering the toggle choose if the discount have to be applied or not for batteria virtual
 */
const BateriaVirtualDiscountToggle = ({
  bateria,
  handleBatteryModeChange,
  virtualBatteryTypes
}) => {

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Typography
        inline variant="body1"
        style={{color: bateria.gestio_descomptes === "aplicar" ? "gray" : "black"}}
      >No</Typography>
      <Switch
        checked={bateria.gestio_descomptes === "aplicar"}
        disable={virtualBatteryTypes.loading}
        onChange={
          (e) => handleBatteryModeChange(
            e.target.checked ?
              virtualBatteryTypes.types[1].name //"aplicar"
              :
              virtualBatteryTypes.types[0].name, //"no_aplicar"
            bateria.bateria.id
          )
        }
      />
      <Typography
        inline variant="body1"
        style={{color: bateria.gestio_descomptes === "aplicar" ? "black" : "gray"}}
      >Sí</Typography>
</Grid>
  );
}

export default BateriaVirtualDiscountToggle;