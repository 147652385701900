import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as invoicesActionCreators from "../../actions/invoices";
import * as contractActionCreators from "../../actions/contracts";
import { regional_settings } from "../../constants";
import { locale_code } from "../../constants/i18n";
import { i18n, dayjs } from "../../config";

import { ContentHeader } from "../ContentHeader";

import { Notification } from "../Notification";
import { SmartTable } from "../SmartTable";
import StripePaymentDialog from "./StripePaymentDialog";
import Skeleton from '@material-ui/lab/Skeleton';

import ChartSkeleton  from "../Chart/ChartSkeleton";
import { BarChart } from '../Chart/BarChart';
import Settings from "../../settings";
import _ from "lodash";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  Box,
  Typography
} from "@material-ui/core";
import {
  ArrowForward,
  ArrowBack,
  ErrorOutline,
} from "@material-ui/icons";
import Filter from "../Filter/Filter";
import { DownloadButton } from "./DownloadButton";
import { Alert } from "@material-ui/lab";

// Payment provider (default is stripe)
const paymentProvider = _.get(Settings, "paymentProvider", "stripe");

// Stripe Key
const stripeKey = _.get(Settings, "stripe.key", null);

function mapStateToProps(state) {
  return {
    data: state.invoices,
    invoices: state.invoices.items,
    itemsOffset: state.invoices.offset_items,
    itemsPage: state.invoices.page_items,
    itemsTotal: state.invoices.total_items,
    invoice_function: state.invoices.invoice_function,
    token: state.auth.token,
    loaded: state.invoices.loaded,
    isFetching: state.invoices.isFetching,
    message_text: state.invoices.message_text,
    filterContract: state.contracts.filterContract,
    toggleLeft: false,
    download_status: state.invoices.download_status,
    filters: state.invoices.filters,
    errors: state.invoices.errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
        invoices: bindActionCreators(invoicesActionCreators, dispatch),
        contract: bindActionCreators(contractActionCreators, dispatch),
    }
  }
}

const style = {
  buttonAdd: {
    marginRight: 20,
  },
  buttonPosition: {
    textAlign: "right",
  },
  table: {
    marginTop: 20,
  },
  chart: {
    marginBottom: 25,
  },
  aggregationsCenter: {
    display: "flex",
  },
  toggle: {
    marginTop: 7,
    marginLeft: 12,
  },
  labelToggle: {
    marginTop: 7,
    marginLeft: 7,
  }
};

class InvoicesView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message_text: null,
      contractId: props.params ? props.params.contractId : null,
      dialogInvoice: null,
      toggleLeft: false,
      itemsOffset: 0,
      itemsPage: _.get(Settings, "invoices.itemsPerPage", 100),
      premadeFilters: [],
      adaptedData: this.JSON_to_arrays([], true),
      chartData: this.chartInvoices([])
    };
  }

  componentDidMount() {

    this.props.actions.invoices.fetchFilters(this.props.token);

    // If they are filtering the invoices
    if(this.props.match.params?.contractName){
      this.setState({
        premadeFilters: 
          [{
            category: { type: "autocomplete", renderText: "Contracte", searchField: "polissa_id.name" },
            value: [this.props.match.params?.contractName], 
            type: 'contains'
          }]
      });
    } else if(this.props.isTabbed) {
      if (!this.props.invoices || this.props.invoices?.length === 0) {
        this.fetchData(true, 0, '');
      } else {
        this.setState({
          adaptedData: this.JSON_to_arrays(this.props.invoices, false), 
          chartData: this.chartInvoices(this.props.invoices) 
        });
      }
    } else {
      this.fetchData(true, 0, '');
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loaded && !prevProps.loaded) {
      if (this.props.data.items) {
        const invoice = this.props.data.items[0];
        switch (this.props.contract_function) {
          case "download":
            if (
              invoice &&
              !(
                invoice.id &&
                invoice.id in this.props.download_status &&
                this.props.download_status[invoice.id]
              )
            )
            this.exportInvoiceFun(invoice.id);
            break;
          case "payment":
            //todo
            break;
          default:
            break;
        }
      }
    }

    if (prevProps.loaded !== this.props.loaded) {
      this.setState({adaptedData: this.props.loaded ? this.JSON_to_arrays(this.props.invoices, false) : this.JSON_to_arrays([], true)});
      this.props.loaded && this.props.invoices && this.setState({chartData: this.chartInvoices(this.props.invoices) });
    }
  }

  fetchData = (initial = true, offset = 0, filters = this.props.filters.currentFilter, itemsPage = this.state.itemsPage) => {
    const token = this.props.token;
    this.props.actions.invoices.fetchInvoices(token, initial, offset, filters, itemsPage);
  };

  toggleRender = (event, status) => {
    this.setState({
      toggleLeft: status,
      message_open: false,
    });
  };

  refreshData = () => {
    this.fetchData(false);
    this.setState({
      message_open: true,
    });
  };

  exportInvoice = (event, invoiceID) => {
    event.preventDefault();
    this.props.actions.invoices.exportInvoice(this.props.token, invoiceID);
  };
  exportInvoiceFun = (invoiceID) => {
    this.props.actions.invoices.exportInvoice(this.props.token, invoiceID);
  };

  previousPage = () => {
    let newOffset = this.props.itemsOffset - this.props.itemsPage;
    if (newOffset < 0) { newOffset = 0 };
    this.setState({itemsOffset: newOffset});
    this.fetchData(false, newOffset);
  };

  nextPage = () => {
    const newOffset = this.state.itemsOffset + this.state.itemsPage;
    this.setState({itemsOffset: newOffset});
    this.fetchData(false, newOffset);
  };

  applySearchFilter = (filters, itemsPage) => {
    this.fetchData(false, this.state.itemsOffset, filters, itemsPage);
    this.setState({itemsPage});
  }

  chartInvoices(invoices) {
    let components = {};
    const number_of_months = 12;

    const current_year = dayjs().year()
    let init_year = current_year;
    //find the earliest year
    invoices.forEach((invoice) => {
      let invoice_year = dayjs(invoice.end_date).year();

      if (invoice_year < init_year) {
        init_year = invoice_year;
      }
    });

    let energy_per_year = {};
    let amount_per_year = {};
    for (let year = init_year; year <= current_year; year++) {
      energy_per_year[year] = [];
      amount_per_year[year] = [];

      // Assign the 12 months
      for (let i = 0; i < number_of_months; i++) {
        energy_per_year[year].push({ name: i, total: 0 });
        amount_per_year[year].push({ name: i, total: 0 });
      }
    }

    invoices.forEach((invoice) => {
      const end_date = dayjs(invoice.end_date);

      // Extract the date
      const month = end_date.month();
      const year = end_date.year();
      const year_lite = year - 2000;

      const sign = invoice.rectifirectificative_type === "A" ? -1 : 1;

      const amount = parseFloat(invoice.amount_total);
      const energy = parseFloat(invoice.energy_consumed) * sign;
      const contract = invoice.contract.name;

      // Ensure initialize correctly components with all CUPS
      if (!(contract in components)) {
        components[contract] = {
          title: contract,
        };
      }

      if(amount_per_year[year][month][contract]) {
        // Add the energy and ammount
        energy_per_year[year][month][contract] += energy;
        amount_per_year[year][month][contract] += amount;
      } else {
        // Set the energy and ammount
        energy_per_year[year][month][contract] = energy;
        amount_per_year[year][month][contract] = amount;
      }
      // Override title by default by shorted mont and the year
      amount_per_year[year][month]["name"] =
        i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
      energy_per_year[year][month]["name"] =
        i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
    });

    let final_amount = [];
    let final_energy = [];
    for (let year = init_year; year <= current_year; year++) {
      for (let month = 0; month < number_of_months; month++) {
        //Select just non-empty elements
        amount_per_year[year][month].total = 0;
        if (Object.keys(amount_per_year[year][month]).length > 2) {
          // Calculate totals for each month
          _.forEach(Object.keys(amount_per_year[year][month]), (k) => {
            if (k !== "total" && k !== "name") {
              amount_per_year[year][month].total +=
                amount_per_year[year][month][k];
              energy_per_year[year][month].total +=
                energy_per_year[year][month][k];
            }
          });

          // Format decimals
          amount_per_year[year][month].total = Number(
            amount_per_year[year][month].total
          ).toFixed(2);
          energy_per_year[year][month].total = Number(
            energy_per_year[year][month].total
          ).toFixed(0);

          const the_amount = Object.assign({}, amount_per_year[year][month]);
          const the_energy = Object.assign({}, energy_per_year[year][month]);

          final_amount.push(the_amount);
          final_energy.push(the_energy);
        }
      }
    }

    return {
      data: {
        energy: final_energy,
        amount: final_amount
      },
      components,
    };
  }

  handleOpenDialog = (e, invoice) => {
    e.preventDefault();
    this.setState({ dialogOpen: true, dialogInvoice: invoice });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false, dialogInvoice: null });
  };

  JSON_to_arrays = (invoices = [], skeleton = false) => {

    const header = 
    [
      {
        title: null,
      },
      {
        title: i18n.t('common:text.invoices_invoice_number'),
      },
      {
        title: i18n.t('common:text.invoices_date'),
      },
      {
        title: i18n.t('common:text.invoices_period'),
      },
      {
        title: i18n.t('common:text.invoices_address'),
      },
      {
        title: i18n.t('common:text.invoices_import'),
      },
      {
        title: i18n.t('common:text.invoices_energy'),
      },
      {
        title: i18n.t('common:text.invoices_payed'),
      },
      {
        title: i18n.t('common:text.invoices_actions'),
      },
    ];

    const props = this.props;
    const that = this;
    const content = skeleton ? 
      [null , null, null].map((skRow, j) => header.map((headerEl, i) => <Skeleton variant="text" key={j+""+i}/>))
    :
      invoices.map((invoice, index) => {

      // Invoice date
      const invoice_date = dayjs(invoice.date).format('L');

      //Start and End date (period)
      const start_date = dayjs(invoice.start_date).format('L');

      const end_date = dayjs(invoice.end_date).format('L');

      const period = start_date + " > " + end_date;

      const downloadButton = <DownloadButton
        invoiceId={invoice.id} 
        onDownload={(invoiceId) => props.actions.invoices.exportInvoice(props.token, invoiceId)} 
      />

      let paid = invoice.paid ? i18n.t('common:text.invoice_paid') : <strong>{i18n.t('common:text.invoice_not_paid')}</strong>;
      if (invoice.paid && invoice.rectificative_type === "A") {
        paid = <strong>{i18n.t('common:text.invoice_refunded')}</strong>;
      }
      if (invoice.amount_pending && invoice.state !== "grouped_proforma") {
        if (invoice.amount_pending != invoice.amount_total) {
          paid = <strong>{i18n.t('common:text.invoices_parcial_payment')}</strong>;
        }

        // We enable a link to show the pending state history only if there
        // is more than one item to show.
        if (
          invoice.pending_state_history &&
          invoice.pending_state_history.length > 1
        ) {
          paid = (
            <div>
              <Button
                onClick={(e) => that.handleOpenDialog(e, invoice)}
                color='primary'
                variant={'text'}
                style={{padding: 0}}
              >
                {paid}
              </Button>
            </div>
          );
        }
      }

      let payment = null;
      switch (paymentProvider) {
        case "stripe":
          if (stripeKey) {
            payment = (
              <StripePaymentDialog
                invoice={invoice}
                onPaymentSuccessful={() => this.refreshData()}
              />
            );
          }
          break;
        default:
          break;
      }

      const values = [
        invoice.amount_debt ? <ErrorOutline color='primary' /> : null,
        invoice.number,
        invoice_date,
        period,
        invoice.cups.street,
        invoice.amount_total_printable,
        `${invoice.energy_consumed.toLocaleString(locale_code)} ${
          regional_settings.energy_unit
        }`,
        paid,
        <div>
          {downloadButton}
          {invoice.amount_debt > 0 ? payment : null}
        </div>,
      ];

      return values;
    });

    return {
      header,
      content,
    };
  };


  render() {
    const { invoices, itemsPage, itemsTotal, itemsOffset, isFetching } = this.props;
    const { toggleLeft } = this.state;

    const onToggle = this.toggleRender;
    const the_toggle = (
      <div>
          <div id="togglePicture" style={style.aggregationsCenter}>
            <div style={style.labelToggle}>
              <span style={{fontWeight: toggleLeft ? "initial" : "bold"}}>
                {i18n.t('common:text.amount')}
              </span>
            </div>
            <div id="toogleElement">
              <Switch onChange={onToggle} checked={toggleLeft} />
            </div>
            <div style={style.toggle}>
              <span style={{fontWeight: !toggleLeft ? "initial" : "bold"}}>
                {i18n.t('common:text.energy')}
              </span>
            </div>
          </div>
      </div>
    );

    const unit = !toggleLeft
      ? regional_settings.currency
      : regional_settings.energy_unit;

    const chart = (
      <BarChart
        data={this.state.toggleLeft ? this.state.chartData.data.energy : this.state.chartData.data.amount }
        components={this.state.chartData.components}
        unit={unit}
      />
    );

    const itemsStart = itemsOffset;
    const itemsEnd = Math.min(itemsStart + itemsPage, itemsTotal);
    const dialogActions = (
        <Button
          key={'close'}
          color={"primary"}
          variant={"text"}
          onClick={this.handleCloseDialog}
        >
          {i18n.t('common:text.invoices_dialog_close')}
        </Button>
      );

    const paymentDialog = (
      <Dialog
        open={this.state.dialogOpen || false}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            this.handleCloseDialog()
          }
        }}
        scroll={"body"}
      >
        <DialogContent>
          <Box mb={2}>
            <Typography variant={"h4"}>{i18n.t('common:text.invoices_dialog_title')}</Typography>
          </Box>
          <table className="table">
            <thead>
              <tr>
                <th>{i18n.t('common:text.invoices_dialog_table_date')}</th>
                <th>{i18n.t('common:text.invoices_dialog_table_description')}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.dialogInvoice &&
                this.state.dialogInvoice.pending_state_history.map(
                  (i, index) => (
                    <tr key={index}>
                      <td>{dayjs(i.change_date).format("L")}</td>
                      <td>{i.pending_state.ov_description}</td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </Dialog>
    );

    const invoiceCategories = [
      { type: "text",         category: "name",     title: i18n.t("common:text.filter_invoices_invoice"),      renderText: i18n.t("common:text.filter_invoices_invoice"), helper: "F123456", searchField: "number" },
      { type: "autocomplete", category: "contract", title: i18n.t("common:text.filter_invoices_contract"),     renderText: i18n.t("common:text.filter_invoices_contract"), helper: "12345", additionalData: this.props.filters.data?.contracts ?? [], searchField: "polissa_id.name" },
      { type: "autocomplete", category: "nif",      title: i18n.t("common:text.filter_invoices_nif_cif"),      renderText: i18n.t("common:text.filter_invoices_nif_cif"), helper: "12345678A", additionalData: this.props.filters.data?.vats ?? [], searchField: "polissa_id.titular_nif" },
      { type: "date",         category: "date",     title: i18n.t("common:text.filter_invoices_date_title"),   renderText: i18n.t("common:text.filter_invoices_date_renderText"), searchField: "date_invoice" },
      { type: "date",         category: "period",   title: i18n.t("common:text.filter_invoices_period_title"), renderText: i18n.t("common:text.filter_invoices_period_renderText"), searchField: ["data_inici", "data_final"] },
      { type: "autocomplete", category: "cups",     title: "CUPS",                                             renderText: "CUPS", additionalData: this.props.filters.data?.cups ?? [], searchField: "cups_id.name" },
      { type: "autocomplete", category: "address",  title: i18n.t("common:text.filter_invoices_address"),      renderText: i18n.t("common:text.filter_invoices_address"), additionalData: this.props.filters.data?.addresses ?? [], searchField: "cups_id.direccio" },
      { type: "numeric",      category: "import",   title: i18n.t("common:text.filter_invoices_import_title"), renderText: i18n.t("common:text.filter_invoices_import_renderText"), units: "€", searchField: "amount_total" },
      // { type: "numeric",      category: "energy",   title: i18n.t("common:text.filter_invoices_energy_title"), renderText: i18n.t("common:text.filter_invoices_energy_renderText"), units: "kWh", searchField: "energia_kwh" },
      { type: "select",       category: "paid",     title: i18n.t("common:text.filter_invoices_state"),        renderText: i18n.t("common:text.filter_invoices_state"), searchField: "state", additionalData: [
          {label: i18n.t("common:text.invoice_paid"), search: "paid"}, {label: i18n.t("common:text.invoice_not_paid"), search: "open"}
        ] 
      }
    ]

    return (
      <>
        <Notification
          message={this.props.message_text}
          time={6000}
          open={true}
        />

        {paymentDialog}

        <ContentHeader
          title={i18n.t('common:text.invoices_view_title')}
          addButton={false}
          refreshButton={true}
          refreshClickMethod={() => this.refreshData()}
        />
        
        <div style={style.table}>

          <Box mb={2}>
            <Typography variant={"h6"}>
              {i18n.t('common:text.invoices_contracts_chart')}
            </Typography>
          </Box>

          {this.props.loaded ? (
            (Array.isArray(invoices) && invoices.length > 0) ? (
              <>
                <div>{the_toggle}</div>
                <div style={style.chart}>{chart}</div>
              </>
            ) : (
              <Box mb={2}>
                {this.props.errors ? 
                  <Alert severity="warning">{i18n.t("common:text.invoices_error_fetching")}</Alert>
                :
                  <Typography variant={"h5"}>
                    {i18n.t('common:text.invoices_view_empty_list')}
                  </Typography>
                }
                
              </Box>
            )
          ) : (
            <>
              <Skeleton animation={false}>{the_toggle}</Skeleton>
              <ChartSkeleton />
            </>
          )}

          <Filter
            categories={invoiceCategories}
            applySearchFilter={this.applySearchFilter}
            disableApply={!this.props.loaded}
            premadeFilters={this.state.premadeFilters}
          />

          <>
            {itemsPage < itemsTotal && (
              <div style={{ textAlign: "center" }}>
                {itemsStart > 0 && (
                  <Button
                    variant={"text"}
                    onClick={() => this.previousPage()}
                    disabled={isFetching}
                  >
                    {<ArrowBack />}
                    {i18n.t("common:text.pagination_back")}
                  </Button>
                )}
                <span>
                  {i18n.t("common:text.pagination_showing", 
                    {init: itemsStart, end: itemsEnd, ammount: itemsTotal})}
                </span>
                {itemsEnd < itemsTotal && (
                  <Button
                    variant={"text"}
                    onClick={() => this.nextPage()}
                    disabled={isFetching}
                  >
                    {i18n.t("common:text.pagination_next")}
                    {<ArrowForward />}
                  </Button>
                )}
              </div>
            )}

            <SmartTable
              header={this.state.adaptedData.header}
              data={this.state.adaptedData.content}
            />
          
            {itemsPage < itemsTotal && (
              <div style={{ textAlign: "center" }}>
                {itemsStart > 0 && (
                  <Button
                    variant={"text"}
                    onClick={() => this.previousPage()}
                    disabled={isFetching}
                  >
                    {<ArrowBack />}
                    {"Anterior"}
                  </Button>
                )}
                <span>
                  Mostrando de {itemsStart} a {itemsEnd} de {itemsTotal}
                </span>
                {itemsEnd < itemsTotal && (
                  <Button
                    variant={"text"}
                    onClick={() => this.nextPage()}
                    disabled={isFetching}
                  >
                    {"Siguiente"}
                    {<ArrowForward />}
                  </Button>
                )}
              </div>
            )}
          </>
          
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesView);

InvoicesView.propTypes = {
  fetchInvoices: PropTypes.func,
  loaded: PropTypes.bool,
  data: PropTypes.any,
  token: PropTypes.string,
};
